import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "antd";

const AccessDenied = () => {
    const {t} = useTranslation();

    return (
        <>
            <div className={'access_denied'}>
                <div className="container">
                    <h1>403<br/>Access denied</h1>
                    <p>The Tenant is not Available anymore.</p>
                    <div className="text-center">
                        {/*<Link to='/'>*/}
                        {/*    <Button type={'primary'}>{t('pageutilities_gotomainpage')}</Button>*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccessDenied;
