import "../learner/styles/index.scss";
import {Route, useHistory} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Layout, Menu, message} from "antd";
import Icon, {
    AppstoreOutlined,
    SettingOutlined,
    GlobalOutlined,
    IdcardOutlined,
    TeamOutlined,
    DashboardOutlined,
    RocketOutlined,
    LockOutlined,
    FileOutlined, ThunderboltOutlined
} from "@ant-design/icons";
import Header from "./components/Header";
import {connect, useSelector} from "react-redux";
import {campaign_get_user_campaigns} from "./rootActions";
import {useLocation} from "react-router";
import {ThemeContext} from "./ThemeContext";
import {useTranslation} from "react-i18next";
import {ApprIcon} from "../admin/redux/actiontypes/apprConstants";
import {ReportsIcon} from "../admin/redux/actiontypes/reportsConstants";
import UserTourWidget from "../admin/components/userTours/UserTourWidget";
import {debounce, getUserToken, hasAlternativePermission, i18nextChangeLanguage} from "./helper";
import i18next from "i18next";
import Variable from "../shared/constants/variables";
import MoodleAlert from "./components/MoodleAlert";
import {ProgramsIcon} from "../admin/components/CustomIcons";
import PageNotFound from "./components/PageNotFound";
import AdminHeader from "../admin/components/AdminHeader";

const {Content, Sider, Footer} = Layout;

const TABLE_WIDTH_BREAKPOINT = 1199.98;

function mapStateToProps(state) {
    return {
        campaigns: state.root.campaigns,
        siteConfig: state.root.siteConfig,
        siteInfo: state.root.siteInfo,
        parentConfig: state.root.parentSiteConfig,
        reloadCampaigns: state.root.reloadCampaigns,
        hasWhiteLabel: state.root.hasWhiteLabel,
        parentHasWhiteLabel: state.root.parentHasWhiteLabel,
        tenantAdmin: Boolean(state.root.tenantAdmin),
        userStatusChecked: Boolean(state.root.userStatusChecked),
    };
}

const mapDispatchToProps = dispatch => ({
    get_user_campaigns: () => dispatch(campaign_get_user_campaigns()),
})

function AuthLayoutRoute({
                             component: Component,
                             campaigns,
                             campaignInfoRequired,
                             siteInfo,
                             siteConfig,
                             parentConfig,
                             get_user_campaigns,
                             reloadCampaigns,
                             hasWhiteLabel,
                             parentHasWhiteLabel,
                             tenantAdmin,
                             userStatusChecked,
                             ...rest
                         }) {
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);
    const {footer_logo: default_footer_logo, header_logo} = useContext(ThemeContext);
    const {openTour} = useSelector(state => state.userTourInfo);

    const [widgetVisible, setWidgetVisible] = useState(!Boolean(localStorage.getItem('hide_tour')));

    const adminRoute = location?.pathname?.startsWith('/admin') || false;
    const courseRoute = /\/programs\/\d+\/courses\/\d+/.test(location?.pathname);
    const is_premium = siteInfo?.capabilities?.advancedmodules || false;
    const showAssetLink = siteInfo?.allow_curriculum_asset_download || false;
    const tenantMigrated = siteInfo?.tenant_legacy_migrated_status || false;

    const forticloud_permissions = useSelector((state) => state?.root?.siteInfo?.forticloud_permissions);

    useEffect(() => {
        if (!forticloud_permissions) {
            return;
        }

        const forticloud_permissions_data = JSON.parse(forticloud_permissions);

        if (forticloud_permissions_data) {
            // User auth passed&url somehow always throw unauthorized issue(SA&T and FortiPhish) - check the portal permission
            if (forticloud_permissions_data.hasOwnProperty('forticloud_user_type')
                && forticloud_permissions_data.hasOwnProperty('should_show_blockage_page')
                && forticloud_permissions_data.hasOwnProperty('sats_admin_permission')
            ) {
                const {
                    forticloud_user_type,
                    should_show_blockage_page,
                    sats_admin_permission
                } = forticloud_permissions_data;

                const fac_error_path = '/fac/error';

                if (should_show_blockage_page) {
                    history.push(fac_error_path);
                } else {
                    switch (forticloud_user_type) {
                        case 'subuser':
                            //history.push(fac_error_path);
                            //break;
                        case 'iam':
                        case 'idp':
                            if (!sats_admin_permission) {
                                history.push(`${fac_error_path}?denied=1`);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }, [forticloud_permissions])

    useEffect(() => {
        const _token = getUserToken();
        if (!_token) {
            history.push('/login');
        }

        // the tenant has been migrated to the REBUILD system;
        if (tenantMigrated) {
            history.push('/tenant-unavailable');
        }

        if (location.pathname === '/asset' && !siteInfo?.allow_curriculum_asset_download) {
            history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if (siteInfo && siteInfo.lang) {
            if (adminRoute) {
                if (i18next.language !== Variable.DEFAULT_LANG) {
                    i18nextChangeLanguage(Variable.DEFAULT_LANG);
                }
            } else {
                i18nextChangeLanguage(siteInfo.lang);
            }
        } else {
            if (i18next.language !== Variable.DEFAULT_LANG) {
                i18nextChangeLanguage(Variable.DEFAULT_LANG);
            }
        }
    }, [adminRoute, siteInfo]);

    useEffect(() => {
        if (widgetVisible) {
            localStorage.removeItem('hide_tour');
        } else {
            localStorage.setItem('hide_tour', true);
        }
    }, [widgetVisible]);

    useEffect(() => {
        if (campaignInfoRequired && (!campaigns || reloadCampaigns)) {
            get_user_campaigns();
        }
    }, [reloadCampaigns, campaignInfoRequired]);

    useEffect(() => {
        let isTabletView = window.innerWidth <= TABLE_WIDTH_BREAKPOINT;
        setCollapsed(isTabletView);

        window.addEventListener('resize', debounce(() => {
            isTabletView = window.innerWidth <= TABLE_WIDTH_BREAKPOINT;
            setCollapsed(isTabletView);
        }, 500));
    }, []);

    const onCollapseOnclick = () => {
        setCollapsed(!collapsed);
    };

    const onMenuItemClick = ({key: path}) => {
        history.push(path);
    }

    let footer_logo = default_footer_logo;

    if (parentHasWhiteLabel) {
        footer_logo = parentConfig?.header_logo || default_footer_logo;
    } else if (hasWhiteLabel) {
        footer_logo = siteConfig?.header_logo || default_footer_logo;
    }

    const items = [
        {
            label: t('pageutilities_navbar_home'),
            key: '/',
            onClick: onMenuItemClick,
            icon: <AppstoreOutlined/>
        },
        {
            label: t('pageutilities_navbar_programs'),
            key: '/programs',
            onClick: onMenuItemClick,
            icon: <ProgramsIcon/>,
        },
        showAssetLink && {
            label: t('pageutilities_navbar_assets'),
            key: '/asset',
            onClick: onMenuItemClick,
            icon: <FileOutlined/>,
        },
        {
            label: t('pageutilities_navbar_settings'),
            key: '/setting',
            onClick: onMenuItemClick,
            icon: <SettingOutlined/>
        },
    ];

    const training_children = [
        {
            label: 'Dashboard',
            key: '/admin/dashboard',
            onClick: onMenuItemClick,
            icon: <DashboardOutlined/>,
        },
        {
            label: 'Campaigns',
            key: '/admin/campaign',
            onClick: onMenuItemClick,
            icon: <RocketOutlined/>,
        },
        {
            label: 'Remediation',
            key: '/admin/remediation',
            onClick: onMenuItemClick,
            icon: <ThunderboltOutlined/>,
        },
        {
            label: 'Reports',
            key: '/admin/reports',
            onClick: onMenuItemClick,
            icon: <Icon component={ReportsIcon}/>,
        }
    ];

    const settings_children = [
        {
            label: 'Domains',
            key: '/admin/domains',
            onClick: onMenuItemClick,
            icon: <GlobalOutlined/>,
        },
        {
            label: 'Users',
            key: '/admin/users',
            onClick: onMenuItemClick,
            icon: <TeamOutlined/>,
        },
        {
            label: 'Account Management',
            key: '/admin/subaccount',
            onClick: onMenuItemClick,
            icon: <IdcardOutlined/>,
        },
        is_premium && {
            label: 'Authentication',
            key: '/admin/authentication',
            onClick: onMenuItemClick,
            icon: <LockOutlined/>,
        },
        is_premium && {
            label: 'Preference',
            key: '/admin/appearance',
            onClick: onMenuItemClick,
            icon: <Icon component={ApprIcon}/>,
        }
    ];

    const resource_children = [
        {
            label: 'Assets',
            key: '/admin/assets',
            onClick: onMenuItemClick,
            icon: <FileOutlined/>,
        }
    ];

    const adminItems = tenantAdmin ? [
        {
            label: 'TRAINING CAMPAIGN',
            key: 'training',
            type: 'group',
            children: training_children,
        },
        {
            label: 'ADMIN SETTINGS',
            key: 'admin_settings',
            type: 'group',
            children: settings_children,
        },
        {
            label: 'RESOURCE',
            key: 'resource',
            type: 'group',
            children: resource_children,
        },
    ] : [];

    const showTourWidget = () => {
        setWidgetVisible(true);
    };

    const _width = 230;
    const _collapsedwidth = 48;

    const valid_admin_route = [
        ...training_children,
        ...settings_children,
        ...resource_children
    ].find((item) => rest?.location?.pathname?.includes(item?.key));

    const isFCMenu = tenantAdmin && adminRoute && siteInfo?.forticloud_permissions && siteInfo?.forticloud_permissions !== 'null';
    const pathname = location.pathname === '/my' ? '/' : location.pathname;

    return ((userStatusChecked && rest?.path === '/admin' && !(rest?.location?.key || valid_admin_route)) ?
            <Route component={PageNotFound}/> :
            <Route {...rest} render={props => (
                <Layout className={`${isFCMenu ? 'fc_menu' : 'sat_menu'}`}>
                    {isFCMenu ?
                        <AdminHeader isSiteAdmin={tenantAdmin} onCollapse={onCollapseOnclick}
                                     collapsed={collapsed}/> :
                        <Header isSiteAdmin={tenantAdmin} header_logo={siteConfig?.header_logo || header_logo}
                                onCollapse={onCollapseOnclick} collapsed={collapsed}/>}
                    <Layout className={`${collapsed ? 'collapsed-layout' : 'expanded-layout'}`}>
                        <Sider
                            collapsed={collapsed}
                            width={_width}
                            collapsedWidth={_collapsedwidth}
                            className={'side_nav'} theme={'light'}>
                            <Menu mode="inline" items={adminRoute ? adminItems : items}
                                  defaultSelectedKeys={[pathname]}
                                  selectedKeys={[pathname]}/>
                        </Sider>
                        <Content className={`body_outer ${adminRoute ? 'body_outer_admin' : ''}`}>
                            <div className={`${adminRoute && openTour ? 'open-tour' : ''} body`}>
                                <MoodleAlert/>
                                <Component {...props} isSiteAdmin={tenantAdmin}/>
                            </div>

                            {widgetVisible && tenantAdmin && adminRoute ? <UserTourWidget widgetVisible={widgetVisible}
                                                                                          setWidgetVisible={setWidgetVisible}/> : ""}
                            <Footer>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="row footer_txt_row">
                                            <div className='footer-panel'>
                                                <a href="https://www.fortinet.com/corporate/about-us/legal"
                                                   className="footer_txt">{t('pageutilities_navbar_terms')}</a>
                                            </div>
                                            <div className='footer-panel'>
                                                <a href="https://www.fortinet.com/corporate/about-us/privacy"
                                                   className="footer_txt">
                                                    {t('pageutilities_navbar_privacy')}
                                                </a>
                                            </div>
                                            <div className='footer-panel'>
                                                <a href="https://www.fortinet.com/corporate/about-us/gdpr"
                                                   className="footer_txt">
                                                    {'GDPR'}
                                                </a>
                                            </div>
                                            {
                                                !widgetVisible ?
                                                    <div className='footer-panel'>
                                                    <span
                                                        className="footer_txt"
                                                        onClick={() => showTourWidget()}
                                                    >
                                                        {'Open Getting Started'}
                                                    </span>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                <span className="footer_logo"
                                      style={{backgroundImage: `url('${footer_logo}')`}}></span>
                                    </div>
                                </div>
                            </Footer>
                        </Content>
                    </Layout>
                </Layout>
            )}/>
    );
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AuthLayoutRoute);
