import React from "react";
import {DatePicker, Form, Select, Switch} from 'antd';
import {useSelector} from "react-redux";
import dayjs from "dayjs";

export const CampaignCertified = ({conditionId=0}) => {
    const {campaigns_for_options: campaignOptions} = useSelector((state) => state?.remediation?.remediationData) || [];
    const currentDate = dayjs();

    const inputItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
            lg: {
                span: 4,
            }
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 12,
            },
        },
    };

    return (
        <div className={'condition-input-item-content'}>
            <Form.Item
                name={`${conditionId}_condition_certificationid`}
                {...inputItemLayout}
                label={'Campaigns'}
                rules={[{required: true, message: 'You must select at least one campaign'}]}>
                <Select showSearch
                        placeholder={'Select campaigns'}
                        className={'input-ht-lg'}
                        options={campaignOptions.filter(item => item?.scheduled !== true)}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }></Select>
            </Form.Item>
            <Form.Item name={`${conditionId}_condition_conditiondate`}
                       initialValue={currentDate}
                       {...inputItemLayout}
                       label={'Allocation date on or after'}
                       hidden>
                <DatePicker format={'MMMM DD, YYYY'} />
            </Form.Item>
            <Form.Item name={`${conditionId}_condition_conditiondateenabled`}
                       initialValue={1}
                       {...inputItemLayout}
                       label={'Condition date enabled'}
                       hidden>
                <Switch />
            </Form.Item>
        </div>
    )
}
