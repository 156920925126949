import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {launchAPP} from "./shared/provider";
import {
    core_get_tenant_theme, loadToken, setAdmin,
    siteInfoLoaded
} from "./shared/rootActions";
import {connect, useDispatch, useSelector} from "react-redux";
import {notification, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {ThemeContext} from "./shared/ThemeContext";
import {createGlobalStyle} from "styled-components";
import Variable from "./shared/constants/variables";
import {getUserToken, hexToRgb, storeToken, switchLanguage} from "./shared/helper";
import {Redirect} from "react-router";
import LoginPage from "./learner/pages/LoginPage";
import ResetPasswordPage from "./learner/pages/ResetPasswordPage";
import ForgotPage from "./learner/pages/ForgotPage";
import AuthLayoutRoute from "./shared/AuthLayoutRoute";
import ProfilePage from "./learner/pages/ProfilePage";
import ProgramsPage from "./learner/pages/ProgramsPage";
import ProgramDetail from "./learner/pages/ProgramDetail";
import ModulePage from "./learner/pages/ModulePage";
import QuizPage from "./learner/pages/QuizPage";
import QuizPlayer from "./learner/pages/QuizPlayer";
import QuizFeedback from "./learner/pages/QuizFeedback";
import SettingPage from "./learner/pages/SettingPage";
import AdminRoutes from "./admin/AdminRoutes";
import MainPage from "./learner/pages/MainPage";
import TokenExpiryTracker from "./shared/components/TokenExpiryTracker";
import PageLoading from "./shared/components/PageLoading";
import {SET_USER_STATUS_CHECKED} from "./shared/rootActionTypes";
import SACAssetPage from "./learner/pages/SACAssetPage";
import PageNotFound from "./shared/components/PageNotFound";
import AccessDenied from "./shared/components/AccessDenied";
import {FCBlockagePage} from "./admin/pages/FCBlockagePage";
import TenantUnavailable from "./shared/components/TenantUnavailable";

/**
 * Maintenance window
 */
const showMaintenanceWindow = () => {
    notification.open({
        className: 'maintenance-window',
        message: 'Service Outage Notice: Security Awareness and Training Service',
        duration: 0,
        type: 'warning',
        description: <>
            <p>We regret to inform you that our Security Awareness and Training service is currently unavailable.
                Our team is actively working to resolve the issue, and we apologize for any inconvenience this may
                cause.</p>
            <p>Please be assured that all efforts are being made to restore the service as soon as possible.
                We appreciate your understanding and patience during this downtime.</p>
            <p>If you have any urgent inquiries or concerns, please reach out to us at
                <a className="ml-1" href="mailto:infosec_awareness@fortinet.com"
                   target="__blank">infosec_awareness@fortinet.com</a>.
            </p>
            <p>Thank you for your cooperation.</p>
        </>,
        onClick: () => {
        },
    });
};


const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

function App({setSiteInfo, siteInfo: site, siteConfig, tenantAdmin, shouldAutoRedirect, userStatusChecked, tenant_loading}) {
    const loginas = useSelector((state) => state?.loginas);
    const [tenantID, setTenantID] = useState(0);
    const [loadingSiteInfo, setLoadingSiteInfo] = useState(false);
    const [loadingSiteTranslations, setLoadingSiteTranslations] = useState(true);
    const [isTenantAdmin, setIsTenantAdmin] = useState(tenantAdmin);

    const dispatch = useDispatch();
    const isAdminRoute = window?.location?.pathname?.startsWith('/admin') || false;
    let reloadInterval = null;

    const loadAppData = (showLoading = false) => {
        launchAPP(() => {
            setLoadingSiteInfo(showLoading);
        }).then(siteInfo => {
            if (siteInfo) {
                if (siteInfo.error) {
                    showMaintenanceWindow();
                } else {
                    setSiteInfo(siteInfo);
                }
            }
        }).finally(() => {
            setLoadingSiteInfo(false);
            dispatch({
                type: SET_USER_STATUS_CHECKED
            });
        })
    }

    useEffect(() => {
        dispatch(core_get_tenant_theme()).then(info => {
            if (info.errorcode && info.errorcode === "invalidtoken") {
                if (!site) {
                    localStorage.removeItem('_token');
                }
            } else {
                if (info && info.active) {
                    setTenantID(info.current);
                }
            }
        }).finally(() => {
        });

        loadAppData(true);

        const translationsLoaded = function (e) {
            setLoadingSiteTranslations(false);
        };

        document.addEventListener('loaded.locales', translationsLoaded)

        const _token = getUserToken();
        if (_token) {
            dispatch(loadToken(_token));
        }

        reloadInterval = setInterval(() => {
            loadAppData(false)
        }, 300000); // 5 Minutes interval. 300000

        return () => {
            if (reloadInterval) {
                clearInterval(reloadInterval);
            }

            document.removeEventListener('loaded.locales', translationsLoaded);
        };
    }, []);

    useEffect(() => {
        let siteAdmin = tenantAdmin;

        if (site) {
            siteAdmin = site.userissiteadmin || site.useristenantadmin;
        }

        setIsTenantAdmin(siteAdmin);
    }, [site, tenantAdmin]);

    useEffect(() => {
        // could have used history.push() but it appears to be in conflict with themes context.
        userStatusChecked && isAdminRoute && (isTenantAdmin || (window.location.href = '/'));
    }, [site]);

    useEffect(() => {
        if (isTenantAdmin || userStatusChecked) {
            dispatch(setAdmin(isTenantAdmin));
        }
    }, [isTenantAdmin, userStatusChecked]);

    useEffect(() => {
        if (loginas?.token) {
            storeToken(loginas.token);
            window.location.href = "/admin/dashboard";
        }
    }, [loginas?.token]);

    const theme = useContext(ThemeContext);
    let themeConfig = siteConfig || theme;
    const GlobalStyles = createGlobalStyle`
      html {
        --nav-bar-color: ${themeConfig.nav_bar_color};
        --nav-bar-color-rgb: ${hexToRgb(themeConfig.nav_bar_color, false)};
        --primary-color: ${themeConfig.primary_color};
        --link-color: ${themeConfig.link_color};
      }
    `;

    const AuthPageRedirect = (location, AuthPage, user) => user ? (
        <Redirect
            to={{
                pathname: "/",
                state: {from: location}
            }}
        />
    ) : <AuthPage/>;


    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = themeConfig.favicon;

    // if site(tenant) config or site translation missing - tenant or site must be down
    if (loadingSiteInfo || loadingSiteTranslations || tenant_loading) {
        return <Spin indicator={antIcon}/>;
    }

    if (process.env.NODE_ENV == 'production') {
        if (!tenantID) {
            return (
                <Router>
                    <Switch>
                        <Route component={PageNotFound}/>
                    </Switch>
                </Router>
            )
        }
    }

    return (
        <>
            <GlobalStyles/>
            <TokenExpiryTracker/>
            <Router>
                <Switch>
                    {
                        shouldAutoRedirect !== null && (
                            <AuthLayoutRoute
                                exact
                                path="/"
                                campaignInfoRequired
                                component={({location}) => (
                                    shouldAutoRedirect ? (
                                        <Redirect
                                            to={{
                                                pathname: "/admin/dashboard",
                                                state: {from: location}
                                            }}
                                        />
                                    ) : (<Redirect
                                        to={{
                                            pathname: "/my",
                                            state: {from: location}
                                        }}
                                    />)
                                )}
                            />
                        )
                    }
                    <AuthLayoutRoute exact campaignInfoRequired component={PageLoading} path="/"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={MainPage} path="/my"/>
                    <AuthLayoutRoute exact component={ProfilePage} path="/profile"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={ProgramsPage} path="/programs"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={ProgramDetail}
                                     path="/programs/:programid"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={ModulePage}
                                     path="/programs/:programid/courses/:courseid"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={QuizPage}
                                     path="/programs/:programid/courses/:courseid/quizzes/:quizid/:state?"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={QuizPlayer}
                                     path="/programs/:programid/courses/:courseid/quizzes/:quizid/attempts/:attemptid"/>
                    <AuthLayoutRoute exact campaignInfoRequired component={QuizFeedback}
                                     path="/programs/:programid/courses/:courseid/quizzes/:quizid/attempts/:attemptid/feedback"/>
                    <AuthLayoutRoute exact component={SettingPage} path="/setting"/>
                    <AuthLayoutRoute exact component={SACAssetPage} path="/asset"/>

                    <AuthLayoutRoute component={AdminRoutes} path="/admin"/>

                    <Route path="/login"
                           render={({location}) => (AuthPageRedirect(location, LoginPage, site?.userid))}
                    />

                    <Route path="/reset"
                           render={({location}) => (AuthPageRedirect(location, ResetPasswordPage, site?.userid))}
                    />

                    <Route path="/forgot"
                           render={({location}) => (AuthPageRedirect(location, ForgotPage, site?.userid))}
                    />

                    <Route path="/access-denied" exact component={AccessDenied}/>
                    <Route path="/tenant-unavailable" exact component={TenantUnavailable}/>
                    <Route path="/fac/error" exact component={FCBlockagePage}/>
                    <Route component={PageNotFound}/>
                </Switch>
            </Router>
        </>
    );
}


const mapStateToProps = (state) => ({
    ...state.root
});

const mapDispatchToProps = dispatch => ({
    setSiteInfo: (siteInfo) => dispatch(siteInfoLoaded(siteInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
